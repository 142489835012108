import {
  Navigate,
  useLocation
} from 'react-router-dom';
import { useEffect } from "react";
import { useAuthChange, AuthChangeEvent, useAuthStatus } from './hooks';
import { useNavigate } from "react-router-dom";
import { Flows, AuthenticatorType } from '../lib/allauth';

export const URLs = Object.freeze({
  LOGIN_URL: '/account/login',
  LOGIN_REDIRECT_URL: '/dashboard',
  LOGOUT_REDIRECT_URL: '/'
});

const flow2path = {};
flow2path[Flows.LOGIN] = '/account/login';
flow2path[Flows.LOGIN_BY_CODE] = '/account/login/code/confirm';
flow2path[Flows.SIGNUP] = '/account/signup';
flow2path[Flows.VERIFY_EMAIL] = '/account/verify-email';
flow2path[Flows.PROVIDER_SIGNUP] = '/account/provider/signup';
flow2path[Flows.REAUTHENTICATE] = '/account/reauthenticate';
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.TOTP}`] = '/account/authenticate/totp';
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.RECOVERY_CODES}`] = '/account/authenticate/recovery-codes';
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.WEBAUTHN}`] = '/account/authenticate/webauthn';
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.TOTP}`] = '/account/reauthenticate/totp';
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.RECOVERY_CODES}`] = '/account/reauthenticate/recovery-codes';
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.WEBAUTHN}`] = '/account/reauthenticate/webauthn';
flow2path[Flows.MFA_WEBAUTHN_SIGNUP] = '/account/signup/passkey/create';

export function pathForFlow(flow, typ) {
  let key = flow.id;
  if (typeof flow.types !== 'undefined') {
    typ = typ ?? flow.types[0];
    key = `${key}:${typ}`;
  }
  const path = flow2path[key] ?? flow2path[flow.id];

  if (!path) {
    throw new Error(`Unknown path for flow: ${flow.id}`);
  }

  return path;
}

export function pathForPendingFlow(auth) {
  if (!auth?.data?.flows || auth.data.flows.length === 0) {
    console.error("No pending flows found in auth.data.flows.");
    return null;
  }

  const flow = auth.data.flows.find(flow => flow.is_pending);

  if (flow) {
    return pathForFlow(flow);
  }

  return null;
}

function navigateToPendingFlow(auth) {
  const path = pathForPendingFlow(auth);
  if (path) {
    return <Navigate to={path} />;
  }

  return null;
}

export function AuthenticatedRoute({ children }) {
  const location = useLocation();
  const [, status] = useAuthStatus();
  const next = `next=${encodeURIComponent(location.pathname + location.search)}`;

  if (status.isAuthenticated) {
    return children;
  } else {
    return <Navigate to={`${URLs.LOGIN_URL}?${next}`} />;
  }
}

export function AnonymousRoute({ children }) {
  const [, status] = useAuthStatus();

  if (!status.isAuthenticated) {
    return children;
  } else {
    return <Navigate to={URLs.LOGIN_REDIRECT_URL} />;
  }
}

/**
 * Redirects users based on authentication changes, such as logging in, logging out, or pending flows.
 */
export function AuthChangeRedirector({ children }) {
  const [auth, event] = useAuthChange();
  const [, status] = useAuthStatus();
  const location = useLocation();
  const navigate = useNavigate();

  // Detect pending email verification and redirect manually
  useEffect(() => {
    if (status.pendingFlow?.id === "verify_email") {
      navigate("/account/verify-email/");
    }
  }, [status.pendingFlow, navigate]);

  switch (event) {
    case AuthChangeEvent.LOGGED_OUT:
      return <Navigate to={URLs.LOGOUT_REDIRECT_URL} />;
    case AuthChangeEvent.LOGGED_IN:
      return <Navigate to={URLs.LOGIN_REDIRECT_URL} />;
    case AuthChangeEvent.REAUTHENTICATED: {
      const next = new URLSearchParams(location.search).get('next') || '/';
      return <Navigate to={next} />;
    }
    case AuthChangeEvent.REAUTHENTICATION_REQUIRED: {
      const next = `next=${encodeURIComponent(location.pathname + location.search)}`;
      const path = pathForFlow(auth.data.flows[0]);
      return <Navigate to={`${path}?${next}`} state={{ reauth: auth }} />;
    }
    case AuthChangeEvent.FLOW_UPDATED:
      const pendingFlow = navigateToPendingFlow(auth);
      if (!pendingFlow) {
        throw new Error("No pending flow detected during FLOW_UPDATED.");
      }
      return pendingFlow;
    default:
      break;
  }

  return children;
}
