import React, { useState } from 'react';
import { useUsernameDetails } from '../../applib/schema';
import {Button} from '../../components/Button';
import FormErrors from '../../components/FormErrors';
import { useUser } from '../../auth';
import { Navigate } from 'react-router-dom';

const ChangeAccountDetails = () => {
  const [username, setUsername] = useState('');
  const { putUsernameDetails } = useUsernameDetails();
  const [response, setResponse] = useState({ fetching: false, success: false, errors: null });
  const user = useUser();

  const submit = async () => {
    setResponse({ ...response, fetching: true, errors: null });
    try {
      await putUsernameDetails({ username });
      setResponse({ fetching: false, success: true, errors: null });
      
    } catch (error) {
      setResponse({ fetching: false, success: false, errors: error.message });
    }
  };

  // Navigate to the account details page upon successful submission
  if (response.success) {
    return <Navigate to="/account/details" />;
  }

  return (
    <div>
      <h1>Edit Account Details</h1>
      <div>Current Username: {user.username}</div>
      <div>
        <label>
          New Username
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            required
          />
        </label>
        {response.errors && <FormErrors errors={response.errors} />}
      </div>
      <Button disabled={response.fetching} onClick={submit}>
        Submit
      </Button>
    </div>
  );
};

export default ChangeAccountDetails;
