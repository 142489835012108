import { useUser } from './auth';
import React, { useEffect, useState, useCallback } from "react";
import styles from "./Dashboard.module.css";
import {getConsumDrinkStats} from './applib/schema';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

export default function Dashboard() {
  const [drinkStats, setDrinkStats] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("30"); // Default: Last 30 days
  const user = useUser();
  const currentYear = new Date().getFullYear(); // Get current year

  const fetchData = useCallback(async () => {
    try {
      const data = await getConsumDrinkStats();
      // Convert date strings to formatted "Month Day" (e.g., "Feb 7")
      const formattedData = data.map(item => {
        const dateObj = new Date(item.date);
        return {
          date: dateObj.toLocaleDateString("en-US", { month: "short", day: "numeric" }), // "Feb 7"
          timestamp: dateObj.getTime(), // Store timestamp for filtering
          total_drinks: item.total_drinks,
        };
      });
      setDrinkStats(formattedData);
    } catch (error) {
      console.error("Error fetching drink stats:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // Apply filter to data
    const now = new Date().getTime();
    let filtered = drinkStats;

    if (filter !== "all") {
      const days = parseInt(filter, 10);
      filtered = drinkStats.filter(item => now - item.timestamp <= days * 24 * 60 * 60 * 1000);
    }

    setFilteredData(filtered);
  }, [filter, drinkStats]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h2>Welcome, {user?.username}!</h2>
        <span className={styles.year}>{currentYear}</span> {/* Year in top right */}
      </div>

      {/* Filter Dropdown */}
      <div className={styles.filterContainer}>
        <label htmlFor="filter">Show data for: </label>
        <select id="filter" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="all">All Time</option>
        </select>
      </div>

      <div className={styles.chartContainer}>
        <h3>Beer Consumption Trend</h3>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={filteredData} barSize={40}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="date" tick={{ fill: "#555" }} />
            <YAxis tick={{ fill: "#555" }} />
            <Tooltip contentStyle={{ backgroundColor: "#f4f4f4", borderRadius: "5px" }} />
            <Bar dataKey="total_drinks" fill="#FF7F50" radius={[10, 10, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
