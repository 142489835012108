import React, { useState } from 'react';
import { postCategory } from '../applib/schema'; // Import the global function
import { useUser } from "../auth"; // Import useUser to get the logged-in user's data
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import FormErrors from '../components/FormErrors';

import beerImage from './assets/beer.png'; // Import the PNG image for beer
import mixedDrinksImage from './assets/mixed.png'; // Import the PNG image for mixed drinks
import styles from './css/CreateCategoryMobile.module.css'

const CreateCategory = () => {
  const user = useUser(); // Get the logged-in user
  const [response, setResponse] = useState({ fetching: false, content: null });
  const navigate = useNavigate(); // Initialize the navigate hook

  // Function to handle submission
  const submitCategory = async (categoryType) => {
    setResponse({ ...response, fetching: true });

    const categoryData = {
      category_name: categoryType, // Dynamically set the category name
      creator: user.id, // Set the creator as the logged-in user's ID
    };

    try {
      const newCategory = await postCategory(categoryData);
      setResponse((r) => ({ ...r, content: newCategory }));
      navigate("/category"); // Navigate to /category after successful submission
    } catch (e) {
      console.error(e);
      window.alert(e);
    } finally {
      setResponse((r) => ({ ...r, fetching: false }));
    }
  };

  return (
    <div className={styles.createCategoryContainer}>
      <h1>Select A Category</h1>

      <FormErrors errors={response.content?.errors} />

      {/* Image Buttons */}
      <div className={styles.categoryButtons}>
        <button
          className={`${styles.categoryButton} ${response.fetching ? 'disabled' : ''}`}
          disabled={response.fetching}
          onClick={() => submitCategory("Beer")}
        >
          <img src={beerImage} alt="Beer Category" className={styles.categoryImage} />
        </button>

        <button
          className={`${styles.categoryButton} ${response.fetching ? 'disabled' : ''}`}
          disabled={response.fetching}
          onClick={() => submitCategory("Mixed Drinks")}
        >
          <img src={mixedDrinksImage} alt="Mixed Drinks Category" className={styles.categoryImage} />
        </button>
      </div>
    </div>
  );
};

export default CreateCategory;
