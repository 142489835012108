const logos = require.context("../../assets/logos", false, /\.(png|jpg)$/);

// Map each logo to the corresponding file name
const beerLogos = {
  "Blue Moon": logos("./Blue-moon-logo.png"),
  Budweiser: logos("./budweiser-logo.png"),
  Chimay: logos("./chimay-logo.png"),
  Corona: logos("./corona-logo.png"),
  Delirium: logos("./delirium.jpg"),
  Guinness: logos("./guinness-draught-logo.png"),
  Heineken: logos("./Heineken-Logo.png"),
  Hoegaarden: logos("./hoegaarden-logo.png"),
  Lagunitas: logos("./lagunitas-logo.png"),
  "Modelo Negra": logos("./modelo-negra-logo.jpg"),
  "Pale Ale": logos("./PaleAle.png"),
  "Pliny the Elder": logos("./Pliny-the-elder-logo.jpg"),
  "Samuel Adams": logos("./samuel-adams-boston-lager-beer-logo.png"),
  Stone: logos("./stone-logo.png"),
  Victoria: logos("./Victoria-logo.png"),
};



const beerData = [
  {
    id:1, 
    name: "Pliny the Elder",
    type: "Double IPA",
    abv: 8.0,
    ibu: 100,
    brewery: "Russian River Brewing Company",
    origin: "Santa Rosa, California, USA",
    description: "A well-balanced double IPA with a strong hop flavor and hints of citrus and pine.",
    pairing: "Spicy foods, grilled meats, and sharp cheeses",
    logo: beerLogos["Pliny the Elder"],
  },
  {
    id:2,
    name: "Guinness Draught",
    type: "Stout",
    abv: 4.2,
    ibu: 40,
    brewery: "Guinness",
    origin: "Dublin, Ireland",
    description: "A creamy, iconic Irish stout with notes of roasted barley and chocolate.",
    pairing: "Oysters, stew, and dark chocolate",
    logo: beerLogos["Guinness"],
  },
  {
    id:3,
    name: "Sierra Nevada Pale Ale",
    type: "Pale Ale",
    abv: 5.6,
    ibu: 38,
    brewery: "Sierra Nevada Brewing Co.",
    origin: "Chico, California, USA",
    description: "A crisp, hop-forward pale ale with notes of pine and citrus.",
    pairing: "Burgers, fried foods, and mild cheeses",
    logo: beerLogos["Pale Ale"],
  },
  {
    id:4,
    name: "Heineken",
    type: "Lager",
    abv: 5.0,
    ibu: 23,
    brewery: "Heineken",
    origin: "Amsterdam, Netherlands",
    description: "A light, easy-drinking lager with a slightly bitter finish.",
    pairing: "Pizza, light salads, and mild cheeses",
    logo: beerLogos["Heineken"],
  },
  {
    id:5,
    name: "Delirium Tremens",
    type: "Belgian Strong Ale",
    abv: 8.5,
    ibu: 26,
    brewery: "Brouwerij Huyghe",
    origin: "Melle, Belgium",
    description: "A fruity and spicy Belgian ale with a warming alcohol finish.",
    pairing: "Seafood, creamy cheeses, and fruit tarts",
    logo: beerLogos["Delirium"],
  },
  {
    id:6,
    name: "Samuel Adams Boston Lager",
    type: "Lager",
    abv: 5.0,
    ibu: 30,
    brewery: "Samuel Adams",
    origin: "Boston, Massachusetts, USA",
    description: "A full-flavored lager with a malty sweetness and crisp hop finish.",
    pairing: "Barbecue, sausages, and pretzels",
    logo: beerLogos["Samuel Adams"],
  },
  {
    id:7,
    name: "Hoegaarden",
    type: "Witbier",
    abv: 4.9,
    ibu: 15,
    brewery: "Brouwerij Hoegaarden",
    origin: "Hoegaarden, Belgium",
    description: "A refreshing wheat beer with hints of orange peel and coriander.",
    pairing: "Light salads, seafood, and citrus desserts",
    logo: beerLogos["Hoegaarden"],
  },
  {
    id:8,
    name: "Chimay Blue",
    type: "Belgian Strong Ale",
    abv: 9.0,
    ibu: 35,
    brewery: "Chimay Brewery",
    origin: "Baileux, Belgium",
    description: "A rich, complex ale with notes of dark fruit, caramel, and spice.",
    pairing: "Roasted meats, blue cheese, and chocolate desserts",
    logo: beerLogos["Chimay"],
  },
  {
    id:9,
    name: "Budweiser",
    type: "Lager",
    abv: 5.0,
    ibu: 12,
    brewery: "Anheuser-Busch",
    origin: "St. Louis, Missouri, USA",
    description: "A light, smooth lager with a slightly sweet finish.",
    pairing: "Grilled chicken, hot dogs, and light snacks",
    logo: beerLogos["Budweiser"],
  },
  {
    id:10,
    name: "Blue Moon Belgian White",
    type: "Witbier",
    abv: 5.4,
    ibu: 9,
    brewery: "Blue Moon Brewing Company",
    origin: "Denver, Colorado, USA",
    description: "A wheat beer with hints of orange peel and coriander.",
    pairing: "Seafood, chicken, and citrus salads",
    logo: beerLogos["Blue Moon"],
  },
  {
    id:11,
    name: "Victoria",
    type: "Vienna Lager",
    abv: 4.0,
    ibu: 20,
    brewery: "Grupo Modelo",
    origin: "Mexico City, Mexico",
    description: "A well-balanced lager with a toasty malt profile and mild bitterness.",
    pairing: "Tacos, enchiladas, and roasted pork",
    logo: beerLogos["Victoria"],
  },
  {
    id:12,
    name: "Lagunitas IPA",
    type: "IPA",
    abv: 6.2,
    ibu: 51,
    brewery: "Lagunitas Brewing Company",
    origin: "Petaluma, California, USA",
    description: "A hoppy IPA with citrus and floral notes.",
    pairing: "Spicy foods, burgers, and fried foods",
    logo: beerLogos["Lagunitas"],
  },
  {
    id:13,
    name: "Corona Extra",
    type: "Lager",
    abv: 4.6,
    ibu: 18,
    brewery: "Grupo Modelo",
    origin: "Mexico City, Mexico",
    description: "A light, crisp lager often served with a lime wedge.",
    pairing: "Tacos, grilled shrimp, and guacamole",
    logo: beerLogos["Corona"],
  },
  {
    id:14,
    name: "Negra Modelo",
    type: "Vienna Lager",
    abv: 5.4,
    ibu: 16,
    brewery: "Grupo Modelo",
    origin: "Mexico City, Mexico",
    description: "A smooth, dark lager with roasted malt flavors and a hint of caramel.",
    pairing: "Grilled meats, mole, and roasted vegetables",
    logo: beerLogos["Modelo Negra"],
  },
  {
    id:15,
    name: "Stone IPA",
    type: "IPA",
    abv: 6.9,
    ibu: 77,
    brewery: "Stone Brewing",
    origin: "Escondido, California, USA",
    description: "A bold, hoppy IPA with notes of citrus and pine.",
    pairing: "Spicy foods, curries, and fried chicken",
    logo: beerLogos["Stone"],
  },
];

export default beerData;
  