import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/TableDrinks.module.css'; // Import the CSS module

const CustomLinkRendererDrinks = ({ value, data }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/drink/create/${data.realCategoryId}`);
  };

  return (
    <button className={styles.linkbutton} onClick={handleNavigate}>
      {value}
    </button>
  );
};

export default CustomLinkRendererDrinks;
