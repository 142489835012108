import React from "react";
import styles from "./css/Feed.module.css";

const Feed = ({ children }) => {
  return (
    <div className={styles.feedContainer}>
      {/* Left Sidebar - Profile & Navigation */}
      <aside className={styles.sidebar}>
        <div className={styles.profile}>
          <img src="/images/profile-placeholder.png" alt="Profile" className={styles.profilePic} />
          <h3>John Doe</h3>
        </div>
        <nav className={styles.navLinks}>
          <a href="/feed/leaderboard">🏆 Leaderboard</a>
          <a href="/dashboard">📊 Your Stats</a>
        </nav>
      </aside>

      {/* Main Feed Section */}
      <main className={styles.mainFeed}>
        <div className={styles.feedContent}>{children}</div>
      </main>

      {/* Right Sidebar - Trending Beers */}
      <aside className={styles.trending}>
        <h4>🔥 Trending Beers</h4>
        <ul>
          <li>🍺 IPA - 320 logs</li>
          <li>🍻 Stout - 250 logs</li>
          <li>🍺 Lager - 210 logs</li>
        </ul>
      </aside>
    </div>
  );
};

export default Feed;
