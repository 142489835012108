import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import ReCAPTCHA from "react-google-recaptcha";
import FormErrors from "../../components/FormErrors";
import { Button } from "../../components/Button";
import WebAuthnLoginButton from "../../mfa/WebAuthnLoginButton";
import ProviderList from "../../socialaccount/ProviderList";
import { useConfig } from "../../auth";
import { login } from "../../lib/allauth";
import styles from "./css/Login.module.css";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [response, setResponse] = useState({ fetching: false, content: null });
  const navigate = useNavigate(); // Hook for navigation
  const googleAPI = process.env.REACT_APP_API_GOOGLE_CAP;
  const config = useConfig();
  const hasProviders = config.data.socialaccount?.providers?.length > 0;

  async function submit() {
    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }
  
    setResponse({ ...response, fetching: true });
  
    try {
      const content = await login({ username, password });
  
      // ✅ Check for pending 2FA authentication
      const pendingFlow = content.data?.flows?.find(flow => flow.id === "mfa_authenticate");
      if (pendingFlow) {
        navigate("/account/authenticate/totp", { state: { username } }); // Pass the username in state
        return;
      }
  
      setResponse((r) => ({ ...r, content }));
    } catch (e) {
      window.alert(e);
    } finally {
      setResponse((r) => ({ ...r, fetching: false }));
    }
  }
  
  

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.loginSection}>
          <Link to="/" className={styles.logoIcon}> 🦊 </Link>
          <form className={styles.form}>
            <FormErrors errors={response.content?.errors} />
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="Username"
              className={styles.input}
              required
            />
            <FormErrors param="username" errors={response.content?.errors} />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className={styles.input}
              required
            />
            <FormErrors param="password" errors={response.content?.errors} />

            <div className={styles.recaptchaContainer}>
              <ReCAPTCHA sitekey={googleAPI} onChange={(token) => setRecaptchaToken(token)} />
            </div>

            <div className={styles.optionsRow}>
              <label>
                <input type="checkbox" className={styles.checkbox} /> Remember me
              </label>
              <Link to="/account/password/reset" className={styles.link}>Forgot your password?</Link>
            </div>

            <Button
              disabled={response.fetching}
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
              className={styles.loginButton}
            >
              Login
            </Button>
          </form>

          {config.data.account.login_by_code_enabled && (
            <div className={styles.alternateLogin}>
              <Link to="/account/login/code" className={styles.secondaryButton}>Mail me a sign-in code</Link>
              <WebAuthnLoginButton>Sign in with a passkey</WebAuthnLoginButton>
            </div>
          )}

          {hasProviders && (
            <div className={styles.providersSection}>
              <h3 className={styles.providersTitle}>Or use a third-party</h3>
              <ProviderList callbackURL="/account/provider/callback" />
            </div>
          )}

          <p className={styles.signupPrompt}>
            Don’t have an account?{" "}
            <Link to="/account/signup" className={styles.link}>Sign up here.</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
