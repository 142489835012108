import React from 'react';
import styles from './Home.module.css';
import { Link, } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <div > <Link to="/">
          🦊 {/* Fox icon for home page */}
        </Link></div>
      <ul className={styles.navLinks}>
        <li className={styles.navItem}>Team
          <div className={styles.dropdown}>
          <a href="/">Brayan Sanchez</a>
          <a href="/">Gaby</a>
          <a href="/">Angel Sanchez</a>

          </div>
        </li>
        <li className={styles.navItem}>Product</li>
        <li className={styles.navItem}>About</li>
        <li className={styles.navItem}>Support</li>
      </ul>
      <div className={styles.navActions}>
        <Link to="/account/login/" className={styles.signInButton}>Login</Link>
        <Link to="/account/signup/" className={styles.signUpButton}>Signup</Link>
        <Link to="/account/password/reset/" className={styles.signUpButton}>Reset Password</Link>
      </div>
    </nav>
  );
};

export default function NavHome() {
  return (
 
      <Navbar />
      
  );
}