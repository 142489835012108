import axios from 'axios';
import { useUser } from '../auth';
import { getCSRFToken } from '../lib/django';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const URLs = Object.freeze({
  CATEGORIES: API_BASE_URL + '/api/categories/',
  DRINKS: API_BASE_URL + '/api/drinks/',
  DRINK_CONSUM_STATS: API_BASE_URL + '/api/stats/beer-consumption-trend/',
  LEADER_STATS: API_BASE_URL + '/api/stats/daily-ranking/',
});

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Include cookies in requests (if required)
});

// Generic function to handle API requests
async function fetchData(url, method = 'GET', body = null) {
  try {
    const options = {
      url,
      method,
      data: body,
      headers: {
        'X-CSRFToken': getCSRFToken(), // Include the CSRF token in headers
      },
    };

    const response = await axiosInstance(options);
    return response.data; // Return data to the caller
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail || error.response.statusText
      : error.message;
    throw new Error('Error: ' + errorMessage);
  }
}

// Function to get categories
export async function getCategories() {
  const data = await fetchData(URLs.CATEGORIES);
  return data; // Return data to the caller
}

// Function to post a new category
export async function postCategory(categoryData) {
  const data = await fetchData(URLs.CATEGORIES, 'POST', categoryData);
  return data; // Return the created category data
}

export async function patchCategories(categoryID, categoryData) {
  const data = await fetchData(URLs.CATEGORIES + `${categoryID}/`, 'PATCH', categoryData);
  return data; // Return the updated drink data
}

export async function deleteCategories(categoryID) {
  const data = await fetchData(URLs.CATEGORIES + `${categoryID}/`, 'DELETE');
  return data; // Return the updated drink data
}


// Function to get drinks
export async function getDrinks() {
  const data = await fetchData(URLs.DRINKS);
  return data; // Return data to the caller
}

// Function to get drinks
export async function postDrinks(drinkData) {
  const data = await fetchData(URLs.DRINKS, 'POST', drinkData);

  return data; // Return data to the caller
}

// Function to post a new category
export async function patchDrinks(drinkID, drinkData) {
  const data = await fetchData(URLs.DRINKS + `${drinkID}/`, 'PATCH', drinkData);
  return data; // Return the updated drink data
}

export async function deleteDrinks(drinkID) {
  const data = await fetchData(URLs.DRINKS + `${drinkID}/`, 'DELETE');
 
  return data; // Return the updated drink data
}


export async function getUsernameDetails(url) {
  const data = await fetchData(url);
  return data; // Return data to the caller
}

export function useUsernameDetails() {
  const user = useUser();
  const usernameURL = API_BASE_URL + `/api/users/${user?.id}/`;

  const putUsernameDetails = async (usernameData) => {
    const data = await fetchData(usernameURL, 'PUT', usernameData);
    return data;
  };

  return { getUsernameDetails, putUsernameDetails };
}

// Function to get drinks
export async function getConsumDrinkStats() {
  const data = await fetchData(URLs.DRINK_CONSUM_STATS);
  return data; // Return data to the caller
}

// Function to get Public LeaderBoards
export async function getLeaderBoardStats() {
  const data = await fetchData(URLs.LEADER_STATS);
  return data; // Return data to the caller
}