import React from 'react';
import styles from './Home.module.css';
import BazanLogo from './images/home/bazan_logo.png';
import { Link} from "react-router-dom";

export default function Home() {
  return (
    <div className={styles.homeContainer}>
      <div className={styles.heroSection}>
        <h1 className={styles.title}>Welcome to Beta!</h1>
        <p className={styles.subtitle}>Brayan Bazan's Website is in Beta Phase.</p>
        <div className={styles.buttonContainer}>
          <Link to="/account/login/" className={styles.signinMainButton}>Login</Link>
        <Link to="/account/signup/" className={styles.signupMainButton}>Signup</Link>
        </div>
      </div>
      <div className={styles.imageSection}>
        <img src={BazanLogo} alt="Beta Logo" className={styles.image} />
      </div>
    </div>
  );
}
