import React, { useState } from "react";
import Sidebar from "./DashboardNavBar";
import { Outlet } from "react-router-dom";
import styles from "./Root.module.css";

export default function Root() {
  const [contentShift, setContentShift] = useState(250); // Dynamically track content margin

  return (
    <div className={styles.appContainer}>
      <Sidebar setContentShift={setContentShift} />
      <div
        className={styles.contentWrapper}
        style={{ marginLeft: `${contentShift}px` }} // Adjust content position dynamically
      >
        <main className={styles.mainContent}>
          <div className="container">
            <Outlet />
          </div>
        </main>
        <footer className={styles.footer}>
          <div className="container">
            <span className="text-body-secondary">
              ⚠️ STILL DEVELOPING THE LOOKS ON THIS WEBSITE. PLEASE DO NOT MIND THE LOOKS!
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
}
