import React, { useState } from 'react';
import FormErrors from '../../components/FormErrors';
import { requestPasswordReset } from '../../lib/allauth';
import { Link } from 'react-router-dom';
import styles from './css/Request.module.css';
import ReCAPTCHA from 'react-google-recaptcha';

export default function RequestPasswordReset() {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState({ fetching: false, content: null });
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const googleAPI = process.env.REACT_APP_API_GOOGLE_CAP;

  function submit() {
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }
  
    setResponse({ ...response, fetching: true });
    requestPasswordReset(email)
      .then((content) => {
        setResponse((r) => ({ ...r, content }));
      })
      .catch((e) => {
        console.error(e);
        window.alert(e);
      })
      .then(() => {
        setResponse((r) => ({ ...r, fetching: false }));
      });
  }

  if (response.content?.status === 200) {
    return (
      <div className={styles.resetContainer}>
        <h1>Password Reset</h1>
        <p>Password reset email has been sent successfully.</p>
      </div>
    );
  }
  return (
    <div className={styles.resetContainer}>
      <div className={styles.resetBox}>
      <div className={styles.logoWrapper}>
      <Link to="/" className={styles.logoIcon}>
          🦊 {/* Fox icon for home page */}
        </Link>
      </div>
      <h1>Reset your password</h1>
      <p>Enter your user account's verified email address, and we will send you a code.</p>

      <FormErrors errors={response.content?.errors} />

      <form className={styles.resetForm} onSubmit={(e) => { e.preventDefault(); submit(); }}>

        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
          required
          className={styles.inputField}
        />
        <div className={styles.recaptchaContainer}>
    <ReCAPTCHA sitekey={googleAPI} onChange={(token) => setRecaptchaToken(token)} />
  </div>
        <button
          type="submit"
          disabled={response.fetching}
          className={styles.resetButton}
        >
          Send password reset email
        </button>
      </form>

      <p className={styles.backToLogin}>
        Remember your password? <Link to="/account/login">Back to login</Link>.
      </p>
      </div>
    </div>
  );
}
