import React, { useState, useEffect } from 'react';
import { getDrinks } from '../applib/schema'; // Import the global fetch function

const Drinks = () => {
  const [drinks, setDrinks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getDrinks();  // Call the global fetch function
        setDrinks(data);  // Set the fetched data to state
      } catch (err) {
        setError(err.message);  // Set error if there's any
      }
    }

    fetchData(); // Fetch data when the component mounts
  }, []); // This will only run once, after the component mounts

  return (
    <div>
      <h1>Drinks</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display error message if any */}
      <table border="1" className="table">
        <thead>
          <tr>
            <th>Drink Name</th>
            <th>Creator</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {drinks.map((drink, i) => (
            <tr key={i}>
              <td>{drink.drink_name}</td>
              <td>{drink.drinker_email}</td>
              <td>{new Date(drink.created_at).toLocaleString() || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Drinks;
