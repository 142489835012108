import React, { useEffect, useState } from "react";
import { useUser } from "../auth";
import styles from "./css/LeaderBoard.module.css";
import { getLeaderBoardStats } from "../applib/schema";
import Feed from "./Feed";

const LeaderBoard = () => {
  const [rankingData, setRankingData] = useState([]);
  const user = useUser();

  useEffect(() => {
    async function fetchRanking() {
      try {
        const data = await getLeaderBoardStats();
        setRankingData(data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    }
    fetchRanking();
  }, []);

  return (
    <Feed>
      <h2 className={styles.leaderboardTitle}>🏆 Daily Beer Leaderboard 🏆</h2>
      {user ? (
        <p className={styles.welcome}>Welcome, {user.username}! Here are today's top drinkers:</p>
      ) : (
        <p className={styles.welcome}>Please log in to see the rankings.</p>
      )}

      <div className={styles.tableContainer}>
        <table className={styles.rankingTable}>
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Drinks Logged</th>
            </tr>
          </thead>
          <tbody>
            {rankingData.length > 0 ? (
              rankingData.map((entry, index) => (
                <tr key={index}>
                  <td>{new Date(entry.date).toLocaleDateString("en-US", { month: "short", day: "numeric" })}</td>
                  <td>{entry.drinker__username}</td>
                  <td>{entry.total_drinks}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No data available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Feed>
  );
};

export default LeaderBoard;
