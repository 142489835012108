import React, { useState } from "react";
import styles from "./css/dragpostdrinks.module.css";
import beerData from "./data/beers/beers";
import { postDrinks } from '../applib/schema';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from "../auth";
import { ToastContainer } from 'react-toastify';
import { showToast } from './components/ToastNotification'; // Import showToast function
import 'react-toastify/dist/ReactToastify.css';

const CreateDrinks = () => {
  const [selectedBeers, setSelectedBeers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const user = useUser();
  const { categoryId } = useParams();
  const navigate = useNavigate();

  const addBeerToPicks = (beer) => {
    if (!selectedBeers.some((b) => b.id === beer.id)) {
      setSelectedBeers([...selectedBeers, beer]);
    }
  };

  const removeBeerFromPicks = (beerId) => {
    setSelectedBeers(selectedBeers.filter((beer) => beer.id !== beerId));
  };

  const getFlagClass = (origin) => {
    if (origin.includes("USA")) return styles["flag-usa"];
    if (origin.includes("Ireland")) return styles["flag-ireland"];
    if (origin.includes("Netherlands")) return styles["flag-netherlands"];
    if (origin.includes("Belgium")) return styles["flag-belgium"];
    if (origin.includes("Mexico")) return styles["flag-mexico"];
    return "";
  };

  const navigateToCreateCategory = () => {
      navigate('/category');
    };


  
    const handleBeerMeClick = async () => {
      if (selectedBeers.length === 0) {
          alert("No beers selected! 🍺");
          return;
      }
  
      // Create an array of drink data for each selected beer
      const drinksData = selectedBeers.map(beer => ({
          category: parseInt(categoryId),
          drink_name: beer.name,
          drinker: parseInt(user.id)
      }));
      try {
          // Send all requests concurrently using Promise.all
          const responses = await Promise.all(drinksData.map(drink => postDrinks(drink)));
  
          if (responses.every(response => response)) {
              showToast('🎉 All drinks added successfully!');
              setSelectedBeers([]); // Clear the selected beers after submission
              navigate(`/category/drinks/${categoryId}`);
          } else {
              showToast('😱 Some drinks failed to add, try again!');
          }
      } catch (error) {
          showToast('😱 Error creating drinks!');
      }
  };
  
  const filteredBeers = beerData.filter(
    (beer) =>
      beer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      beer.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    
    <div className={styles.container}>
      <ToastContainer/>
      {/* Top Controls */}
      {/* Beer Me Button */}
      <button className={styles.beerMeButton} onClick={navigateToCreateCategory}>
            Back to Categories
          </button>
      <div className={styles.topControls}>
        <div className={styles.searchBarContainer}>
          <input
            type="text"
            placeholder="Search for beers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={styles.searchInput}
          />
        </div>
      </div>

      {/* Content Section */}
      <div className={styles.content}>
        {/* Beer List */}
        <div className={styles.beerList}>
          <h2 className={styles.heading}>Beers</h2>
          <div className={styles.cardContainer}>
            {filteredBeers.map((beer) => (
              <div
                key={beer.id}
                className={styles.card}
                onClick={() => addBeerToPicks(beer)}
              >
                <div className={`${styles.flagStrip} ${getFlagClass(beer.origin)}`}></div>
                <img
                  src={beer.logo}
                  alt={beer.name}
                  className={styles.cardImage}
                />
                <div className={styles.cardContent}>
                  <h3 className={styles.cardName}>{beer.name}</h3>
                  <p className={styles.cardType}>{beer.type}</p>
                  <p className={styles.cardStats}>ABV: {beer.abv}%</p>
                  <p className={styles.cardStats}>IBU: {beer.ibu}</p>
                  <p className={styles.cardDescription}>{beer.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Selected Picks */}
        <div className={styles.selectedPicks}>
          <h2 className={styles.heading}>Your Picks ({selectedBeers.length})</h2>
          <div className={styles.cardContainer}>
            {selectedBeers.map((beer) => (
              <div
                key={beer.id}
                className={styles.card}
                onClick={() => removeBeerFromPicks(beer.id)}
              >
                <div className={`${styles.flagStrip} ${getFlagClass(beer.origin)}`}></div>
                <img
                  src={beer.logo}
                  alt={beer.name}
                  className={styles.cardImage}
                />
                <div className={styles.cardContent}>
                  <h3 className={styles.cardName}>{beer.name}</h3>
                  <p className={styles.cardType}>{beer.type}</p>
                  <p className={styles.cardStats}>ABV: {beer.abv}%</p>
                  <p className={styles.cardStats}>IBU: {beer.ibu}</p>
                  <p className={styles.cardDescription}>{beer.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Beer Me Button */}
      <button className={styles.beerMeButton} onClick={handleBeerMeClick}>
            🍺 Beer Me!
          </button>
    </div>
  );
};

export default CreateDrinks;
