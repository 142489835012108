'use client';
import React, { useCallback,
  useMemo,
  useRef,
  useState,
useEffect, } from 'react';

import { AllCommunityModule, 
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ModuleRegistry,
  RowApiModule,
  RowSelectionModule,
  themeQuartz,
  SelectEditorModule,
  NumberEditorModule,
  NumberFilterModule,
  TextEditorModule,
  TextFilterModule,
  } from 'ag-grid-community';
  import CustomHeader from "./customHeader";

import { AgGridReact, } from 'ag-grid-react';
import { getCategories, postCategory, patchCategories, deleteCategories } from '../applib/schema';
import { useUser } from "../auth";
import styles from "./css/TableDrinks.module.css"
import CustomLinkRendererList from './components/CustomLinkRendererDrinkList';
import CustomLinkRendererDrinks from './components/CustomLinktoAddDrinks';
import { ToastContainer } from 'react-toastify';
import { showToast } from './components/ToastNotification'; // Import showToast function
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"; // Import useNavigate

ModuleRegistry.registerModules(
  [AllCommunityModule, 
  ClientSideRowModelApiModule,
  RowSelectionModule,
  RowApiModule,
  ClientSideRowModelModule,
  NumberEditorModule,
  TextEditorModule,
  TextFilterModule,
  NumberFilterModule,
  SelectEditorModule,]);


const categories = ["Beer", "Mixed Drinks"];

const Categories = () => {
  const user = useUser();
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate(); // Initialize navigation

  const [columnDefs, ] = useState([
    { headerName: "ID", field: "id", sortable: true, headerComponentParams: { menuIcon: "fa-external-link-alt" }, },
    { headerName: "Category Name", field: "category_name", editable: true,  minWidth: 120,  cellEditor: "agSelectCellEditor", cellEditorParams: {
      values: categories,
    },},
    { 
      headerName: "Add Drink", 
      field: "addDrinkLink", 
      minWidth: 120,
      cellRenderer: 'customLinkRendererDrinks',

  },
    { headerName: "Drinks", field: "drinkLink", minWidth: 120, cellRenderer: 'customLinkRendererList',},
    
  
  ]);


  const components = useMemo(() => {
    return {
      ColumnHeader: CustomHeader,
      customLinkRendererList: CustomLinkRendererList,
      customLinkRendererDrinks: CustomLinkRendererDrinks,
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      headerComponentParams: {
        menuIcon: "fa-filter",
      },
    };
  }, []);

  const rowSelection = useMemo(() => {
    return { mode: "multiRow" };
  }, []);

  // to use myTheme in an application, pass it to the theme grid option
  const myTheme = themeQuartz
  .withParams({
    accentColor: "#00EE50",
    backgroundColor: "#1f2836",
    browserColorScheme: "dark",
    foregroundColor: "#FFF",
    headerBackgroundColor: "#010101",
    headerColumnResizeHandleColor: "#FFFFFFAB",
    headerFontSize: 14,
 
});

  const fetchData = useCallback(async () => {
    try {
      const data = await getCategories();
      if (data) {
        setRowData(data.map((category, index) => ({
          id: index + 1,  // Generating a sequential ID starting from 1
          realCategoryId: category.id,  // Real category ID from the database
          category_name: category.category_name,
          creator: user.display,
          drinkLink: 'View Drinks',
          addDrinkLink: 'Add Drinks',
        })));
      }
    } catch (error) {
      showToast('📊 Error fetching data!');
    }
  }, [user.display]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api;
  }, []);

  const onRemoveSelected = async () => {
    const selectedData = gridRef.current.getSelectedRows();
    const deletePromises = selectedData.map(async (data) => {
      try {
        const response = await deleteCategories(data.realCategoryId);
        if (response === '') {
          showToast('🗑️ Category Deleted!');
          return { success: true, data };
        } else {
          showToast('😱 Error Deleting Category!');
          return { success: false, data };
        }
      } catch (error) {
        showToast('😱 Error Deleting Category!');
        return { success: false, data };
      }
    });

    // Wait for all delete operations to complete
    const results = await Promise.all(deletePromises);
    const successfulDeletes = results.filter(result => result.success).map(result => result.data);

    // Apply transaction to remove successfully deleted rows from the grid
    if (successfulDeletes.length > 0) {
        gridRef.current.applyTransaction({ remove: successfulDeletes });
    }
};

  

  const navigateToCreateCategory = () => {
    if(rowData.length >=2){
      showToast('😱 You already have 2 unique Categories');
      return;
    }else{
      navigate('/category/create/');
    }
    
  };

  const addNewRow = () => {
    const allRowData = [];
    gridRef.current.forEachNode(node => allRowData.push(node.data));
    const nextId = allRowData.length > 0 ? Math.max(...allRowData.map(row => Number(row.id))) + 1 : 1;

    const newRowData = {
        isNew: true,
        id: nextId,
        category_name: '',
        creator: user.display,
        drinkLink: 'View Drinks',
        addDrinkLink: 'Add Drinks'
    };

    if(rowData.length >=2){
      showToast('😱 You already have 2 unique Categories');
      return;
    }

    try {
        gridRef.current.applyTransaction({ add: [newRowData] });
        showToast('🎉 Added Row!');

    } catch (error) {
      showToast('😱 Error adding new row');
        newRowData.id += 1; // Attempt to correct the error by incrementing the ID
        gridRef.current.applyTransaction({ add: [newRowData] });
    }
  };

  const refreshGrid = () => {
    fetchData();
  };

  const getRowId = useCallback(params => {
    return String(params.data.id);
  }, []);

  const onCellValueChanged = async ({ data, colDef }) => {
    if (colDef.field === 'category_name' && data.isNew) {
      const categoryData = {
        category_name: data.category_name,
        creator: parseInt(user.id)
      };
  
      try {
        const newCategoryData = await postCategory(categoryData);
        if (newCategoryData) {
          showToast('🎉 Category added successfully!');
        } else {
          showToast('😱 Error: Could not create Category, try again!');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          showToast(`Error: ${error.response.data.detail}`);
        } else {
          showToast('😱 Error: This category already exists for the user.');
        }
      }
    } else {
      const categoryData = {
        category_name: data.category_name,
        creator: parseInt(user.id)
      };
  
      try {
        const updatedCategoryData = await patchCategories(data.realCategoryId, categoryData);
        if (updatedCategoryData) {
          showToast('🎉 Category Updated successfully!');
        } else {
          showToast('😱 Failed to update the category!');
        }
      } catch (error) {
        showToast('😱 Error updating category!');
      }
    }
  };
  

    return (
      <div className={styles.container}>
        <ToastContainer/>
          <div className={styles.buttonContainer}>

            <button className={styles.agButton} onClick={navigateToCreateCategory}>Add Categories (Mobile)</button>
            <button className={styles.agButton} onClick={addNewRow} >Add Category (WEB)</button>
            <button className={styles.agButton} onClick={refreshGrid}>Refresh Grid</button>
            <button className={styles.agButton} onClick={onRemoveSelected}>Remove Selected</button>
            </div>
            <div className={styles.gridContainer}>
            <AgGridReact
            theme={myTheme}
            ref={gridRef}
            rowData={rowData}
            getRowId={getRowId}
            columnDefs={columnDefs}
            components={components}
            defaultColDef={defaultColDef}
            rowSelection={rowSelection}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
      />
    </div>
        </div>
  );
};

export default Categories;
