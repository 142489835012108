import { useState, useEffect } from 'react'
import { AuthChangeRedirector, AnonymousRoute, AuthenticatedRoute } from './auth'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import Dashboard from './Dashboard'
import Login from './account/login/Login'
import RequestLoginCode from './account/login/RequestLoginCode'
import ConfirmLoginCode from './account/login/ConfirmLoginCode'
import Logout from './account/logout/Logout'
import Signup from './account/signup/Signup'
import ProviderSignup from './socialaccount/ProviderSignup'
import ProviderCallback from './socialaccount/ProviderCallback'
import Home from './Home'
import ChangeEmail from './account/email/ChangeEmail'
import ManageProviders from './socialaccount/ManageProviders'
import VerifyEmail, { loader as verifyEmailLoader } from './account/email/VerifyEmail'
import VerifyEmailByCode from './account/email/VerifyEmailByCode'
import VerificationEmailSent from './account/email/VerificationEmailSent'
import RequestPasswordReset from './account/password/RequestPasswordReset'
import ChangePassword from './account/password/ChangePassword'
import MFAOverview, { loader as mfaOverviewLoader } from './mfa/MFAOverview'
import ActivateTOTP, { loader as activateTOTPLoader } from './mfa/ActivateTOTP'
import DeactivateTOTP from './mfa/DeactivateTOTP'
import RecoveryCodes, { loader as recoveryCodesLoader } from './mfa/RecoveryCodes'
import AddWebAuthn from './mfa/AddWebAuthn'
import SignupByPasskey from './mfa/SignupByPasskey'
import ReauthenticateWebAuthn from './mfa/ReauthenticateWebAuthn'
import ListWebAuthn, { loader as listWebAuthnLoader } from './mfa/ListWebAuthn'
import GenerateRecoveryCodes, { loader as generateRecoveryCodesLoader } from './mfa/GenerateRecoveryCodes'
import ResetPassword, { loader as resetPasswordLoader } from './account/password/ResetPassword'
import AuthenticateTOTP from './mfa/AuthenticateTOTP'
import AuthenticateRecoveryCodes from './mfa/AuthenticateRecoveryCodes'
import AuthenticateWebAuthn from './mfa/AuthenticateWebAuthn'
import ReauthenticateRecoveryCodes from './mfa/ReauthenticateRecoveryCodes'
import ReauthenticateTOTP from './mfa/ReauthenticateTOTP'
import CreateSignupPasskey from './mfa/CreateSignupPasskey'
import Reauthenticate from './account/authflow/Reauthenticate'
import Sessions from './usersessions/Sessions'
import Categories from './drinks/categories'
import Drinks from './drinks/all-drinks'
import CategoryDrinks from './drinks/category-drinks'
import CreateCategory from './drinks/category-post-mobile'
import CreateDrinks from './drinks/category-drinks-post'
import AccountDetails from './account/userdetails/UserDetails'
import ChangeAccountDetails from './account/userdetails/ChangeUserDetails'
import LeaderBoard from "./feed/LeaderBoard";
import Feed from "./feed/Feed";
import Root from './Root'
import { useConfig } from './auth/hooks'
import NavHome from "./HomeNavBar";

function createRouter (config) {
  return createBrowserRouter([
    {
      path: '/',
      element: (<><NavHome /> <Home /></>)
    },
    {
      path: '/account/login',
      element: <AnonymousRoute><Login /></AnonymousRoute>
    },
    {
      path: '/account/login/code',
      element: <AnonymousRoute><RequestLoginCode /></AnonymousRoute>
    },
    {
      path: '/account/login/code/confirm',
      element: <AnonymousRoute><ConfirmLoginCode /></AnonymousRoute>
    },
    {
      path: '/account/authenticate/totp',
      element: <AnonymousRoute><AuthenticateTOTP /></AnonymousRoute>
    },
    {
      path: '/account/authenticate/recovery-codes',
      element: <AnonymousRoute><AuthenticateRecoveryCodes /></AnonymousRoute>
    },
    {
      path: '/account/provider/callback',
      element: <ProviderCallback />
    },
    {
      path: '/account/provider/signup',
      element: <AnonymousRoute><ProviderSignup /></AnonymousRoute>
    },
    {
      path: '/account/signup',
      element: (
        <> <NavHome/> <AnonymousRoute><Signup /></AnonymousRoute></>)
    },
    {
      path: '/account/signup/passkey',
      element: <AnonymousRoute><SignupByPasskey /></AnonymousRoute>
    },
    {
      path: '/account/signup/passkey/create',
      element: <AnonymousRoute><CreateSignupPasskey /></AnonymousRoute>
    },
    {
      path: '/account/verify-email',
      element: config.data.account.email_verification_by_code_enabled ? <VerifyEmailByCode /> : <VerificationEmailSent />
    },
    {
      path: '/account/verify-email/:key',
      element: <VerifyEmail />,
      loader: verifyEmailLoader
    },
    {
      path: '/account/password/reset',
      element: <AnonymousRoute><RequestPasswordReset /></AnonymousRoute>
    },
    {
      path: '/account/password/reset/key/:key',
      element: <AnonymousRoute><ResetPassword /></AnonymousRoute>,
      loader: resetPasswordLoader
    },

    {
      path: '/',
      element: <AuthChangeRedirector><Root /></AuthChangeRedirector>,
      children: [
        {
          path: '/dashboard',
          element: <AuthenticatedRoute><Dashboard /></AuthenticatedRoute>
        },

        {
          path: '/account/email',
          element: <AuthenticatedRoute><ChangeEmail /></AuthenticatedRoute>
        },
        {
          path: '/account/logout',
          element: <Logout />
        },
        {
          path: '/account/providers',
          element: <AuthenticatedRoute><ManageProviders /></AuthenticatedRoute>
        },
        {
          path: '/account/password/change',
          element: <AuthenticatedRoute><ChangePassword /></AuthenticatedRoute>
        },
        {
          path: '/account/2fa',
          element: <AuthenticatedRoute><MFAOverview /></AuthenticatedRoute>,
          loader: mfaOverviewLoader
        },
        {
          path: '/account/reauthenticate',
          element: <AuthenticatedRoute><Reauthenticate /></AuthenticatedRoute>
        },
        {
          path: '/account/reauthenticate/totp',
          element: <AuthenticatedRoute><ReauthenticateTOTP /></AuthenticatedRoute>
        },
        {
          path: '/account/reauthenticate/recovery-codes',
          element: <AuthenticatedRoute><ReauthenticateRecoveryCodes /></AuthenticatedRoute>
        },
        {
          path: '/account/reauthenticate/webauthn',
          element: <AuthenticatedRoute><ReauthenticateWebAuthn /></AuthenticatedRoute>
        },
        {
          path: '/account/authenticate/webauthn',
          element: <AnonymousRoute><AuthenticateWebAuthn /></AnonymousRoute>
        },
        {
          path: '/account/2fa/totp/activate',
          element: <AuthenticatedRoute><ActivateTOTP /></AuthenticatedRoute>,
          loader: activateTOTPLoader
        },
        {
          path: '/account/2fa/totp/deactivate',
          element: <AuthenticatedRoute><DeactivateTOTP /></AuthenticatedRoute>
        },
        {
          path: '/account/2fa/recovery-codes',
          element: <AuthenticatedRoute><RecoveryCodes /></AuthenticatedRoute>,
          loader: recoveryCodesLoader
        },
        {
          path: '/account/2fa/recovery-codes/generate',
          element: <AuthenticatedRoute><GenerateRecoveryCodes /></AuthenticatedRoute>,
          loader: generateRecoveryCodesLoader
        },
        {
          path: '/account/2fa/webauthn',
          element: <AuthenticatedRoute><ListWebAuthn /></AuthenticatedRoute>,
          loader: listWebAuthnLoader
        },
        {
          path: '/account/2fa/webauthn/add',
          element: <AuthenticatedRoute><AddWebAuthn /></AuthenticatedRoute>
        },
        {
          path: '/account/sessions',
          element: <AuthenticatedRoute><Sessions /></AuthenticatedRoute>
        },
        {
          path: '/category',
          element: <AuthenticatedRoute><Categories /></AuthenticatedRoute>
        },
        {
          path: '/category/create',
          element: <AuthenticatedRoute><CreateCategory /></AuthenticatedRoute>
        },
        {
          path: '/category/drinks/:categoryId',
          element: <AuthenticatedRoute><CategoryDrinks /></AuthenticatedRoute>
        },
        {
          path: '/category/drinks',
          element: <AuthenticatedRoute><Drinks /></AuthenticatedRoute>
        },
        {
          path: '/drink/create/:categoryId',
          element: <AuthenticatedRoute><CreateDrinks /></AuthenticatedRoute>
        },
        {
          path: '/account/details',
          element: <AuthenticatedRoute><AccountDetails /></AuthenticatedRoute>
        },
        {
          path: '/account/edit/details',
          element: <AuthenticatedRoute><ChangeAccountDetails /></AuthenticatedRoute>
        },
        {
          path: '/feed',
          element: <AuthenticatedRoute><Feed /></AuthenticatedRoute>
        },
        {
          path: '/feed/Leaderboard',
          element: <AuthenticatedRoute><LeaderBoard /></AuthenticatedRoute>
        },
      ]
    }
  ])
}

export default function Router () {
  // If we create the router globally, the loaders of the routes already trigger
  // even before the <AuthContext/> trigger the initial loading of the auth.
  // state.
  const [router, setRouter] = useState(null)
  const config = useConfig()
  useEffect(() => {
    setRouter(createRouter(config))
  }, [config])
  return router ? <RouterProvider router={router} /> : null
}